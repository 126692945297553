.menu-collapser {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 8px;
  color: #FFF;
  margin-top: 25px;
  font-size: 16px;
  line-height: 48px;
  background-color: $rojo_oscuro;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .flotas {

    width: 100% !important;

  }

}
.collapse-button {
  position: absolute;
  right: 8px;
  top: 50%;
  width: 40px;
  padding: 7px 10px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  background-color: #0E0E0E;
  border-radius: 2px;
  cursor: pointer;

  transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover,
  &:focus {
    color: #FFF;
    background-color: #040404;
  }

  .icon-bar {
    display: block;
    height: 2px;
    width: 18px;
    margin: 2px 0;
    background-color: #F5F5F5;
    border-radius: 1px;
  }
}

ul.slimmenu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  &.collapsed {
    li {
      display: block;
      width: 100%;
      z-index: 10;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      > ul {
        position: static;
        display: none;
      }

      a {
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);

        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .sub-toggle {
        height: 40px;
        display: block;
      }
    }
  }

  li {
    position: relative;
    float: left;
    display: inline-block;
    background-color: #AAA;

    ul {
      margin: 0;
      list-style-type: none;

      li {
        background: #bbbbbb;
      }
    }

    > ul {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 999;
      display: none;
      width: 100%;

      > li ul {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 999;
        display: none;
        width: 100%;
      }
    }

    a {
      display: block;
      padding: 12px 64px 12px 16px;
      color: #333;
      font-size: 12px;
      font-weight: 400;
      transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -webkit-transition: background-color 0.5s ease-out;
      margin-right: 7px;

      &:hover {
        text-decoration: none;
        background-color: #999;
      }
    }

    .sub-toggle:hover {

      background: $rojo_oscuro;

    }

    .sub-toggle {
      background: none repeat scroll 0 0 $rojo_oscuro;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
      width: 19px;
      height: 100%;
      text-align: center;
      cursor: pointer;
      display: none;

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        margin-right: -0.25em;
        vertical-align: middle;
      }

      > i {
        display: inline-block;
        color: #333;
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }

  > li {
    border-left: 1px solid #999;

    &:first-child,
    &.has-submenu + li {
      border-left: 0;
    }
  }

  .has-submenu {

    ul {

      min-width: 250px;
      padding-left: 0 !important;

    }

    li {

      min-width: 240px;

    }

  }
}

.flotas{

  background: white !important;
  border: 2px solid #A9A9A9;

  li {

    a {

      text-align: center;
      margin-right: 0 !important;
      background: white !important;

    }

  }

}

.noMargin_submenu {

  li {

    a {

      margin-right: 0 !important;

    }

  }

}

.absoluto {
  position: absolute;
  width: 100%;
  z-index: 99;
}