@media screen and (max-width:1200px){
  .texts-offer{
    width:100%;
    right:initial;
    height:100%;
    left:initial;
    max-width:100%;

    &.contactFormOffer{
      height:auto;
      ul{
        display:block;
        .details-offer{
          display: block;
        }
      }
      .title-offer{
        text-align: left;
      }
    }



    .column1,.column2{
      margin: 0 20px;
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
    ul{
      display: none;
    }

    .title-offer{
      text-align: center;
    }



  }
  .link-offer{
    margin:auto!important;
  }
}

@media screen and (max-width:992px){
  .row-familias{
    display:none;
  }
  .titulo_footer i.fa{
    display:block;
  }

  .has-submenu{
    line-height:25px;
  }

  .img_flotas{
    width:100%;
    height:auto;
    max-height:312px;
  }

  .flotas .title-submenu img{
    width:70%;
    margin:auto;
  }
  .showmobile{
    display:block!important;
a{
    padding:12px!important;
}

  }
  footer .logo_footer{
    max-width:200px;
    width:100%;
  }
  .logo-responsive{

    height: 48px;
    padding: 0 8px;
    color: #FFF;
    //margin-top: 25px;
    font-size: 16px;
    line-height: 48px;
    background-color: #a00000;
    width:20%;
    display: block;
    float: left;
    z-index: 10000;
    position: relative;
    img{width:140px;}
  }






}

@media screen and (max-width:468px){
  #formularioAlquiler .boton {
    background-color: #F2FF31;
    color: black;
    font-size: 1.2em;
  }


}

@media screen and (max-width:660px){
  .texts-offer {
    .title-offer{
      font-size:18px;
      margin:5px auto;
    }
    .details-offer{
      font-size:16px;
      display:none;
    }
    .claim-offer{
      font-size:18px;
      margin-top:5px;
    }
  }
}


