$gris: #444444;
$verde: #a3b612;
$gris_oscuro: #363635;

$verde_footer: #b9cd1b;
$gris_footer: #525252;
$gris_groups: #dddddd;
$verde_button: #b9cd1a;
$gris_groups_tite: #f5f5f5;
$input_font_color: #908f8f;
$activo: #d3d3d3;
$redbutton: #ff4900;

//$rojo: #b11d05;
//$rojo_oscuro: #7D1504;
$rojo: #b11d05;
$rojo_oscuro: #a00000;
$amarillo: #F2FF31;
$white:#FFF;

@font-face {

  font-family: 'Titillium';
  src: url(/fonts/Titillium-Regular.otf);

}

@font-face {

  font-family: 'Rexlia';
  src: url(/fonts/Rexlia.ttf);

}

body {

  background: white;
  font-family: "Titillium";
  font-size: 18px;
  position: relative;

  h1 {

    font-family: "Rexlia";

  }

}

#header_container {

  height: auto;
  margin-bottom: 55px !important;

}

.plantilla_reserva {

  margin-top: 50px;

}

.cabecera {

  position: relative;
  background: $rojo_oscuro;
  min-height: 100px;
  color: white;
  font-size: 18px;

  .btn_clientes {

    margin-top: 15px;

  }

  ul {

    line-height: 73px;

  }

}

// Slimmenu

.logo-responsive {
  display: none;

}

.slimmenu {

  font-family: "Rexlia" !important;

  li {

    background-color: $rojo_oscuro !important;

    a {

      color: white !important;
      padding: 12px 8px!important;
      font-size: 10px !important;

    }

    a:hover {

      background-color: $rojo_oscuro !important;

    }

  }
  .has-submenu .flotas {

    li {

      h3 {
        font-size: 1.5em;
      }

      .title-submenu {
        color: black !important;

      }

      &:hover h3 {
        color: white !important;
      }

    }
  }

}

.slimmenu > li {

  border-left: none !important;

}

.margin_top_10 {

  margin-top: 10px;

}

.tituloh1 {

  h1 {

    margin-top: 0 !important;

  }

}

#logo {

  text-align: center;
  margin-top: 25px;

}

.absoluto {

  position: absolute;
  width: 100%;
  z-index: 99;

}

.imgRentForm {

  padding-bottom: 20px;
  width: 100%;
  img {
    width: 220px;
  }

}

.rentform {

  //background: $rojo_oscuro;
  background: $gris_oscuro;
  width: 100%;
  height: auto;
  top: 0;
  border-radius: 10px;
  margin-top: 20px;

  img {

    margin-top: 20px;

  }

}

a {

  img {

    outline: none;

  }

}

.img_cabecera {

  width: 100%;

  img {

    width: 100%;
    object-fit: cover;

  }

}

.tabs_rentvalles {

  font-size: 15px;
  height:100px;
  @media screen and (max-width:768px){
    height:auto;
  }

  a {

    color: black !important;
    cursor: pointer;

  }
  .active {

    a {

      background: $verde_button !important;

    }

  }

}

.steps_finish {

  width: 70%;

}

.goTop {

  position: fixed;
  width: 70px;
  bottom: 10px;
  right: 2%;

}

.carousel-inner {

  img {

    max-height: 450px;

  }

}

.div_contacto {

  background: #ececef;
  min-height: 60px;
  width: 100%;
  color: black;
  text-align: right;
  font-size: 25px;
  font-weight: bold;

  .contact_phones {

  }

  .subDiv {

    width: auto;
    float: right;

  }

  #texto {

    span {

      b {

        // line-height: 55px;

      }

    }

  }

  span {

    margin-right: 15px;

    b {

      font-size: 25px;

    }

  }

  img {

    margin-right: 10px;

  }

}

.seccion {

  margin-top: 50px;

  h1 {

    text-decoration: underline;

  }

  .titulo {

    text-align: center;

  }

  span {

    color: $verde;

  }

}

.visa-mastercard {
  width: 100px;
}

footer {

  .titulo_footer {

    border-bottom: 1px solid white;
    width: 100%;
    margin: 10px auto !important;
    text-transform: uppercase;

  }
  .contact-items-footer {
    i.fa {
      font-size: 1em;
      width: 30px;
      text-align: center;
    }
    i.fa-mobile {
      font-size: 1.6em;
    }
    i.fa-phone {
      font-size: 1.3em;
    }
  }
  .row-familias {
    margin-top: 0px !important;
  }

  .list-pages {
    list-style: none;
    padding: 0;
    .titulo_footer {
      text-transform: uppercase;
    }
  }

  #footer_superior {

    width: 100%;
    background: $rojo_oscuro;
    margin-top: 50px;
    color: white;

    a {

      text-decoration: none;
      color: white;

    }

    a:hover {

      text-decoration: none;

    }

    .row {

      margin-top: 25px;
      margin-bottom: 25px;

    }

    .botones_footer {

      button {

        background: $rojo_oscuro;
        border: 0;
        min-width: 150px;
        border-bottom: 1px solid #707f03;
        margin-top: 15px;

      }

    }

    .col1, .col2, .col3 {
      margin-top: 15px;
    }

  }

  #footer_central {

    .slimmenu {

      li {

        background: #525252 !important;

      }

    }

    background: $gris_footer;
    color: white;
    text-align: right;
    min-height: 80px;
    color: white;

    ul {

      list-style: none;
      margin-top: 20px;

      a {

        color: white;
        margin-right: 20px;

        li {

          display: inline;

        }

      }

      a:hover {

        color: $gris_footer;
        text-decoration: none;
        background: $verde;

      }

    }

  }

  #footer_inferior {

    background: $gris_oscuro;
    min-height: 100px;
    width: 100%;
    color: #707070;
    font-size: 17px;

    a {

      text-decoration: none;
      color: white;

    }

    a:hover {

      text-decoration: none;
      color: white;

    }

    img, p {

      margin-top: 25px;

    }
    img.logo-footer {
      width: 200px;
    }

  }

  .logo_footer {
    width: 100%;
    margin-top: 10px;
  }
}

//Slider

/* --- start --- this style is constant ---------- */
@keyframes slider-key-next {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@keyframes slider-key-left {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes slider-key-previous {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes slider-key-right {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.slider {
  overflow: hidden;
  position: relative;

}

.slider > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
}

.slider > ul > li {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.slider > ul > li.active {
  visibility: visible;
  left: 0;
}

.slider > ul > li.left {
  animation-name: slider-key-left;
}

.slider > ul > li.next {
  animation-name: slider-key-next;
}

.slider > ul > li.right {
  animation-name: slider-key-right;
}

.slider > ul > li.previous {
  animation-name: slider-key-previous;
}

/* --- end --- this style is constant ---------- */

/* -- your style here -- */
.slider {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.slider > button {
  transition: 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #FAFAFA;
  opacity: 0.5;
  border: 0;
  outline: none;
  padding: 0;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.slider > button:hover {
  opacity: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.slider > button:nth-child(2) {
  left: 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAEAUlEQVR4Xu3dO24UQRCAYftYcAGfABFa4nUciDEcgQCJOwAShCBE7hMACY+A7mAly7K9NdNd1fX4LU0A9M5M1/8x3iUwpyd8lZ7Aaends/kTABRHAAAAFJ9A8e3zBABA8QkU3z5PAAAUn0Dx7fMEAEDxCRTfPk8AAJSdwOO282/teF92Am3jVZ8Az9reX7bjVzvOKiOoCOAQ//AXvzSCagCuxy+PoBKA2+KXRlAFwLH4ZRFUACCNXxJBdgBb419FcK/94kv2j4iZAeyN35u/bcfDdvwBQMwJEF/YLeMTgPjC+H1ZNgDE3xA/GwDib4yfCQDxd8TPAoD4O+NnAED8gfjRARB/MH5kAMSfED8qAOJPih8RAPEnxo8GgPiT40cCQHyF+FEAEF8pfgQAxFeM7x0A8ZXjewZAfIP4XgEQ3yi+RwDEN4zvDQDxjeN7AkD8BfG9ACD+ovgeABB/YfzVAIi/OP5KAMR3EH8VAOI7ib8CAPEdxbcGQHxn8S0BEN9hfCsAxHca3wIA8R3H1wZAfOfxNQEQP0B8LQDEDxJfAwDxA8WfDYD4weLPBED8gPFnARiJ/6ndxIt2/As6P6+3/aHd2KXk5kZ/RtBIfMn9sWbfBB60l72RvHQEAPElE16zRh0A8deElV5VFQDxpRnWrVMDQPx1UbdcGQBbppVwrRqAPqun7bhIOLRMW1IF0Af1pB2vMk0s2V7UAfR59f92rSMY+SiZbO5utmMCoO/2UTteg8BN+MONmAEYRfC5neB5O/66G2HsG/rYbt/kn4IPYxp5ErxrJ+lif8eeecy7n/n9GwQBDcwEMPrtgCfBAkCzAYBgQcSRS2oAAMFIEePXagEAgXHIvZfTBACCvVUMX6cNAASGMfdcygIACPaUMXqNFQAQGAXdehlLACDYWsdgvTUAEBhE3XKJFQBAsKWQ8tpVAECgHFZ6+pUAQCCtpLhuNQAQKMaVnNoDABBISimt8QIABEqBj53WEwAQHKul8OfeAIBAIfJdp/QIAASGCLwCAIERAs8AQGCAwDsAECgjiAAABIoIogAAgRKCSABAoIAgGgAQTEYQEQAIJiKICgAEkxBEBgCCCQiiAwDBIIIMAEAwgCALABDsRJAJAAh2IMgGAAQbEWQEAIINCLICAIEQQWYAIwh+tBffb8dX4RzDLssOYA+CHv+sHf1n7aX/qgBgC4JS8ftgqgCQICgXvxqAuxCUjF8RwE0IysavCuAqgp+V3vDd9I620nuA6/s/b7/xvcq7/ds+zlQGkP4jnmSDAJBMKfEaACSOK9kaACRTSrwGAInjSrYGAMmUEq8BQOK4kq0BQDKlxGsAkDiuZGsAkEwp8RoAJI4r2RoAJFNKvOY/NR8wkHwupfAAAAAASUVORK5CYII=);
}

.slider > button:nth-child(3) {
  right: 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAEDklEQVR4Xu3cTW5TMRSG4XSAQMASmHUOEkwAiQ0gMWQBIFaAWA8qYgZijFhA1wXnQkPTNsn133GP/b2VPKjqe22f74nj3kg52fAjXYET6dWz+A0AxBEAAADiFRBfPjsAAMQrIL58dgAAiFdAfPnsAAAQr4D48tkBACBegc3mlVXg1NqZYiXUd4Al/J/W7lv7YO2zGgJlANvwH1yE/lsRgSqA6+FvX/hyCBQBHApfEoEagLXw5RAoAUgNXwqBCoAnluq5te2BL/WwP/2ZQAXAXUv8u7U3qcnv9JsagQqAJU8Q7NGvBAAEAPhbAXaCHQhqO8B26SC4qIQqAHYCAPB2sFRAeQfg7QAA/09DsmcCdoDLE7EkAgBc/d9YDgEAbj4ckUIAgP0fDsggAMDhT4ckEADg+MeD0yMAwPrnw1MjAMA6gKkfGwMgDcC0CACQDmBKBADIAzAdAgDkA5gKAQDKAEyDAADlAKZAAIA6AMMjAEA9gKERAKANgGERAKAdgCERAKAtgOEQAKA9gKEQAMAHwDAIAOAHYAgEAPAFEB4BAPwBhEYAgD4AwiIAQD8AIREAoC+AcAgA0B9AKAQAuB0AYRDkAHhks35xe/WacuQ7tqqP1p4VrK7Jt5flAHhrk/xWMFEu8atANQIA+IXT685VCADQKybfcYoRAMA3mJ53L0IAgJ4R+Y+VjQAA/qH0HAEAPasdbKwl/PfWznLmxQ6QU624fZfw31n7kjtFAORWLF7/4vCXpQAgXqA5M6oKPxfA8ij4ec7s6LtageVR8CdrT1d73uxQHX4ugII5csmRCtyzv/2w9rqgSk3CB0BB5RtdEiJ8ADRKM/M2YcIHQGZyDbqHCh8ADRLNuEW48AGQkV5l15DhA6Ay1cTLw4YPgMQEK7qFDh8AFckmXBo+fAAkpFjYZYjwAVCY7splw4QPgPYAhgofAG0BDBc+ANoBGDJ8ALQBMGz4AKgHMHT4AKgDMHz4ACgHMEX4ACgDME34AMgHMFX4AMgDMF34AEgHMGX4AEgDMG34AFgHMHX4ADgOYPrwAXAYgET4ANgPQCZ8ANwEIBU+AK4CkAsfAJcAJMMHwD8AsuEDQDx8dQDSr/ztu1/OV8SsPzcbpwfhX2SlCIDwd16oagAI/9ourQSA8Pe8RasAIPwD5zMVAI9t/efWHmaeU5t9G1fmuN26qwBYCvrS2q8MBNOHr/gcIBWBRPiKAFJ2ApnwVQEcQyAVvjKAfQjkwlcHsIvggf1S9H373Y7rTgMp/RdwqITLwfDU2lenGoe+LQBCx+M/OQD41zj0CAAIHY//5ADgX+PQIwAgdDz+kwOAf41DjwCA0PH4Tw4A/jUOPQIAQsfjPzkA+Nc49AgACB2P/+QA4F/j0CP8AQt1MpCfJsp4AAAAAElFTkSuQmCC);
}

.slider > ul > li > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.slider > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
}

.slider > ul > li h1,
.slider > ul > li p {
  position: relative;
  padding: 0 15%;
  color: #fff;
  font-family: sans-serif;
}

.slider {
  img {

    width: 100%;
  }
}

// end slider

.boton {

  width: 100%;
  border-radius: 5px;
  color: white;
  background: $rojo_oscuro;
  border: none;
  height: 60px;
  margin-bottom: 10px;
  outline: 0;

}

.padding20px {

  padding: 20px;

}

.div_consulta img {
  width: 100%;
}

#formularioAlquiler {

  //padding: 20px;
  margin-top: 25px;

  input[type="text"], input[type="time"], input[type="date"], input[type="number"] {

    width: 100%;
    height: 40px;
    color: $input_font_color;

  }
  .nav-tabs>li>a{color:white;}
  .nav-tabs>li.active>a, .nav-tabs>li:hover>a{
    background-color:$amarillo;
    color:black;

  }

  textarea {
    color: $input_font_color;
    width: 100%;
  }

  > form > p, > form > #div_devolucion_mbl > p {
    color: white;
  }

  select {

    width: 100%;
    color: $input_font_color;
    height: 40px;

  }

  .input_md {

    width: 49% !important;

  }
  .boton {
    background-color: $amarillo;
    color: black;
  }

  .extraservices-list {
    padding: 0;
    list-style: none;
    .extraservice-item {

      height: auto;
      display: block;
      float: left;
      margin-top: 20px;
      width: 100%;
      .title-services-list {
        font-size: 1.2em;
        font-weight: bold;
      }

      ul.list-inline {

        li {
          select {
            width: 100%;

          }
          //width:49%!important;

        }
      }
    }

  }

}

.title-services-list {
  color: $rojo_oscuro;
}

.recuadro {

  min-height: 250px;
  border: 1px solid $gris_groups;
  margin: 50px 0 50px 0;
  position: relative;

  .cabecera_recuadro {

    width: 100%;
    position: relative;
    min-height: 50px;
    color: black;
    background: $gris_groups_tite;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;

    p {

      margin-left: 10px;

    }

  }

  .emptyGroup {

    width: 100%;
    text-align: center;
    line-height: 150px;

  }

}

#groups, #reserva, .reservation_form {

  h1 {

    color: black;

    span {

      color: $verde_button;

    }

  }

}

.margin15 {

  margin: 15px;

}

.caracteristicasVehiculo {

  h3 {

    color: black;
    font-weight: bold;

  }

  div {

    margin-left: 30px;

  }

}

.detallesGrupo {

  font-size: 13px;
  font-weight: bold;
  color: black;
  margin: 10px;
  float: right;

}

.texAlignCenter {

  text-align: center;

}

.textAlignLeft {

  text-align: left;

}

.infoHorarios {

  margin: 0 auto;
  width: 320px;
  text-align: left;

}

.copyDerecho {

  color: black;

  div {

    border-top: 1px solid $verde_button;
    border-bottom: 1px solid $verde_button;

  }

}

.caracteristicasVehiculoReserva {

  margin-left: 0;

}

.serviciosAlquiler {

  div {

    margin-left: 30px;
    margin-bottom: 25px;

  }

}

.precioFinal {

  margin-right: 20px;
  margin-left: 20px;
  text-align: right;

  span {

    font-size: 50px;
    color: $verde_footer;

  }

}

.formularioReserva {

  background: $gris_oscuro;
  color: white;
  padding: 20px;
  border-radius: 5px;

  .imagen {

    width: 100%;
    text-align: center;

  }

}

#formulario_consulta2 {

  display: none;
  position: relative;
  margin-bottom: 50px;

}

.step {

  height: 50px;
  width: 112px;
  background: $gris_groups_tite;
  margin-right: 4px;
  color: $input_font_color;
  text-align: center;
  line-height: 50px;

}

.responsivo {

  display: none;

}

.divStep {

  padding-left: 0;
  text-align: center;

  p {

    font-size: 15px;
    line-height: 18px;
    margin-top: 10px;

  }

}

.activo {

  background: $activo;
  color: white;

}

.reservation_form {

  h1 {

    margin-bottom: 50px;

  }

  input[type="text"], input[type="number"], input[type="time"], input[type="date"], input[type="email"] {

    width: 70%;
    height: 45px;
    background: $gris_groups_tite;
    border: none;

  }

  #bloqueDatos {

    margin-bottom: 50px;

    span {

      display: block;

    }

  }

  #bloqueDetalles {

    border-top: 8px solid $verde_button;
    color: black;

    h3 {

      font-weight: bold;
      margin-top: 0;

    }

  }

}

.okButton {

  color: white;
  width: 100%;
  border: none;
  height: 50px;
  background: $verde_button;

}

.cancelButton {

  background: $redbutton;
  color: white;
  width: 100%;
  border: none;
  height: 50px;

}

#btnAreaClientes {

  border: none;
  background: $amarillo;
  color: black;
  height: 46px;
  width: 145px;
  margin-top: 15px;
  line-height: 0;
  font-family: "Titillium";
  font-size: 17px;

  img{
    float:left;
  }
  .txtbtn {
    position: relative;
    float: left;
    display: flex;
    width: 100px;
    vertical-align: middle;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    height: 28px;
    line-height: 15px;
  }


}

#staticpage {
  p {
    img {
      height: auto !important;
      width: 100% !important;
    }
  }
  .alignCenter {
    .img-responsive {
      max-height: 450px;
      height: auto;
      margin: auto;
    }
  }
}

#pack {

  text-align: center;

  p {

    img {

      height: auto !important;
      width: 100% !important;

    }

  }

  img {

    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    object-fit: cover;

  }

  .border {

    border-top: 8px solid $verde_button;
    border-bottom: 8px solid $verde_button;

  }

  ul {

    text-align: left;

  }

}

.flotas {

  color: black;

  img {

    width: 100%;
    object-fit: cover;

  }

}

.img_flotas {

  width: 100%;
  object-fit: cover;
  height: 312px;

}

.extras_gratis {

  float: left;
  margin-left: 0 !important;
  width: auto;

}

.floatLeft {

  float: left;

}

.floatRight {

  float: right;

}

.dropdown-menu {
  //background:$gris
  background: $rojo_oscuro;

}

.caracteristicas-l {

  float: left;
  width: 285px;

}

.caracteristicas-r {

  float: right;
  margin: 50px;
  margin-top: 20px;

}

.imgGrupo {

  img {

    width: 300px;
    height: auto;
    margin: 15px;
    object-fit: cover;
    display: block;

  }

  .buttonMasInfo {

    width: 95%;
    border-radius: 5px;
    color: #525252;
    background: #f5f5f5;
    border: none;
    height: 45px;
    margin-bottom: 10px;
    outline: 0;
    margin-left: 15px;
    display: flex!important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: max-content;

    img {

      width: auto;
      display: inherit;
      height: auto;
      margin: 10px;

    }

  }

  .buttonMasInfo:hover {

    background: #e2e2e2;

  }

}

.black {

  color: black;
  font-weight: bold;

}

.mcontent {

  padding: 45px;

  input {

    width: 100% !important;
    height: 60px !important;

  }

  label {

    color: black;

  }

  div {

    width: 100%;
    text-align: center;

    h1, h2 {

      font-weight: bold;
      color: $verde_button;

    }

  }

  .boton {

    color: white;
    height: 80px !important;
    font-size: 30px;

  }

  .cuadro_alerta {

    background: $verde_button;
    color: white;
    text-align: left;

    p {

      margin: 15px;

    }

  }

}

.servicios {

  font-size: 17px;

  img {

    width: 100%;

  }

}

.alignCenter {

  text-align: center;

}

#devolucion {

  p {

    text-align: left;

  }

  h2 {

    color: black;

  }

}

.contenido {

  margin: 20px;

}

.imagediv {

  text-align: center;

}

.campaign {

  margin-top: 25px;

  div {

    width: 100%;
    text-align: center;

  }

  h2 {

    color: black;

  }

  img {

    width: 100%;
    object-fit: cover;
    height: 240px

  }

  p {

    margin-top: 25px;

  }

}

.paginador {

  text-align: center;

}

.fontType {

  text-decoration: none;
  color: black;

}

.fontType:hover {

  color: black;
  text-decoration: none;

}

.sitemap {

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 1em;
    text-indent: -.7em;

    ul {

      li {

        a {

          color: #cccccc;
          text-decoration: none;

        }

      }

    }

  }

  li::before {
    content: "• ";
    color: $verde_button; /* or whatever color you prefer */

  }

  a {

    text-decoration: none;
    color: #666;

  }

  a:hover {

  }

}

.radioVehicle {

  margin: 0 !important;
  float: right;
  margin-right: 20px !important;
  margin-top: 20px !important;

}

.input_disabled {

  background: #ddd;
  color: #ccc;

}

.vehicleSelected {

  border: 2px solid #b9cd1a;
  box-shadow: 2px 2px 5px #b3b2b2;

}

.width98percentage {

  width: 98% !important;

}

.btnLoQuieroDiv {

  margin-right: 17px;

}

.services {

  p {

    width: 100% !important;
    padding: 0 !important;

  }

}

.seccion {

  p {

    img {

      width: 100% !important;
      height: auto !important;

    }

  }

}

#precios {

  .modal-dialog {

    width: 50% !important;

  }

  table {

    border: solid 1px #ccc;
    width: 100%;

    tr:nth-child(2n) {

      background: #eee;

    }

    td {

      padding: 5px;

    }

  }

}

#date_error {

  #office_msg {

    span {

      font-family: "Titillium" !important;
      font-size: 18px !important;

    }

  }

}

.imgCabeceraProm {

  p {

    img {

      width: 100%;
      object-fit: cover;

    }

  }

}

.packForm {

  text-align: left;

  h1 {

    text-align: center;

  }

  input {

    width: 50%;

  }

  textarea {

    width: 100%;
    height: 166px;

  }

}

.hidden-desktop {
  visibility: hidden;
}

.hidden-mobile {
  display: block;
}

#navigation li {
  text-transform: uppercase;
}

.list-services {
  margin-top: 40px;
}

.card-service {
  min-height: 250px;
  height: auto;
  margin-bottom: 20px;
  img {
    width: 100%;

  }
  .title {
    text-align: center;
  }
}

.has-submenu ul.subcat {
  min-width: 380px !important;
  li {
    width: 380px !important;
    &:hover {
      background-color: $rojo_oscuro !important;
    }
  }
}

.subcat li a {

  padding: 0px 12px !important;
}

.showmobile {
  display: none !important;
}

#staticpage .list-services img {
  object-fit: cover;
  height: 250px;
}

#barracookies {
  text-transform: none;
  display: none;
  z-index: 99999;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  min-height: 40px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  line-height: 20px;
  font-family: verdana;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  a:nth-child(1) {
    padding: 4px;
    background: #4682B4;
    border-radius: 5px;
    text-decoration: none;
  }
  #barracookies a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
    text-decoration: underline;
    background: none;
  }
}



@media (max-width: 1200px) {

  #formulario_consulta {

    display: none;

  }

  #formulario_consulta2 {

    display: block;

  }

}

@media (max-width: 725px) {

  .div_contacto {

    font-size: 23px;

  }

}



@media (max-width: 992px) {

  #precios {

    .modal-dialog {

      width: 100% !important;

    }

  }

  .step {

    width: 100%;
    margin-top: 10px;

  }

  .normal {

    display: none;

  }

  .responsivo {

    display: inline;

  }

  .hidden-desktop {
    visibility: visible;
  }
  .hidden-mobile {
    display: none;
  }

}

@media (max-width: 991px) {

  .reservation_form {

    input[type="text"], input[type="number"], input[type="time"], input[type="date"], input[type="email"] {

      width: 100%;

    }

  }

  .packForm {

    input {

      width: 100%;

    }

  }

}

@media screen and (min-width: 993px) and (max-width: 1199px){
  .logo-responsive{
    display:block;
    margin-top:25px;
    float: left;
    margin-right: 20px;
    img {
      width: 140px;
      vertical-align: middle;
      line-height: 100px;
    }
  }
}

@media screen and (max-width: 480px) {

  .imgGrupo {

    img {

      width: 100%;
      margin: 0;

    }

    .buttonMasInfo {

      margin-left: 0 !important;

    }

  }

}

@media screen and (max-width: 992px) {
  .absoluto {

    display: none;

  }
}

.img-responsive {
  margin: auto;
}

#pagar, #pagarOficina {
  display: none;
}


.fullwidth{width:100%;}

/**********************/
/**********************/
/*SLIDER HOME OFERTA */

.texts-offer {
  position: fixed;
  z-index: 10000000000000000000;
  /* background: orange; */
  color: black;
  //width: 60%;
  max-width: 800px;
  width:auto;
  height: auto;
  top:1px;
  right: 7px;
  display:grid;
  grid-template-columns:1fr  ;
  font-family: Titillium;
  background: rgba(0,0,0,0.5);
  padding: 20px!important;
  //left: calc(100% - 868px);
  left: calc(0% + 38%);
  .title-offer{
    color:white;
    font-family: Rexlia;
    .smalltext{
      font-size:0.85em;

    }
    .bigtext{
      font-size:1.2em;
    }

  }
  ul{
    padding: 0;
    width: 100%;
    margin: auto;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-top: 10px;


  }
  .details-offer{
    list-style-type:none;
    color:$amarillo;
    font-family:Rexlia;
    text-transform:initial;
    &:before{content:"- ";}
  }
  .claim-offer{
    color: $white;
    font-family:Rexlia;
    font-size:2em;
    text-transform: uppercase;
    text-align: center;
    text-align: left;
  }

  &.contactFormOffer{
    position: relative;
    width:80%;
    margin:auto;
    margin-bottom:20px;
    z-index:1;
    left:initial;
  }
  }

  .link-offer{
    width:auto;
    margin:auto;
    display: flex;
    justify-content:center;
    align-items:center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    text-transform:uppercase;
    &:hover{
      text-decoration: none;
      color:white;
    }
    &:visited{
      text-decoration:none;
      color: $white;
    }
  }
  .column2{
    display: flex;
    justify-content: space-evenly;
    animation: center;
    flex-direction: column;
    align-items: left;

  }

.formulario-oferta{
  display: grid;
  width:80%;
  margin:auto;
  grid-template-columns: 1fr;
}

/**********************/
/**********************/